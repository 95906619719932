<script>
  import { Slide } from 'vue-burger-menu'
  export default {
    name: 'SiteHeader',
    components: {
      Slide,
    },
  }
</script>

<template>
  <div class="m-8 md:flex justify-between items-center">
    <img src="@/assets/logo.svg" class="w-32 md:w-48 m-0" />
    <div class="hidden md:block">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/services">Services</router-link>
      <router-link to="/gallery">Gallery</router-link>
      <router-link to="/contact">Contact</router-link>
    </div>
    <slide class="md:hidden" :closeOnNavigation="true" right>
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/services">Services</router-link>
      <router-link to="/gallery">Gallery</router-link>
      <router-link to="/contact">Contact</router-link>
    </slide>
  </div>
</template>

<style scoped>
  a {
    @apply text-3xl font-header text-blue-dark px-4;
  }
</style>

<style>
.line-style.bm-burger-bars {
  border-radius: 3px;
}
.bm-menu {
  @apply bg-tan;
}
.bm-burger-button {
  top: 47px;
}
.cross-style {
  @apply mr-8 mt-5 right-0 !important;
}
.bm-cross {
  @apply bg-white h-6 !important;
}
.bm-menu a {
  @apply text-white tracking-wider text-right block pr-8;
}
</style>