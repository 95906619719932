import Vue from 'vue'
import App from './App.vue'
import router from './router'
import EasySlider from 'vue-easy-slider';

import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

Vue.config.productionTip = false

Vue.use(EasySlider);

import "tailwindcss/tailwind.css"

const firebaseConfig = {
  apiKey: "AIzaSyAFIHfG4aUsIJ3dbolxu44ojOoCFA4LJEE",
  authDomain: "rdrockai.firebaseapp.com",
  projectId: "rdrockai",
  storageBucket: "rdrockai.appspot.com",
  messagingSenderId: "959664880712",
  appId: "1:959664880712:web:6df1f23ff9760db529ec7d",
  measurementId: "G-QKBE2TMB0H"
};
firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics();

const remoteConfig = getRemoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3,
  fetchTimeoutMillis: 500,
};
remoteConfig.defaultConfig = ({
  'body_copy': {},
});

Vue.prototype.$remoteConfig = remoteConfig;
Vue.prototype.$logEvent = (event) => logEvent(analytics, event);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
