<script>
  export default {
    name: 'About'
  }
</script>

<template>
  <div>
    <h1>About</h1>
  </div>
</template>

<style scoped>

</style>