<template>
  <div id="app" class="text-center">
    <site-header v-if="showTest" />
    <router-view v-if="showTest" />
    <div v-else class="mt-24">
      <img class="w-1/3 mx-auto mb-10" src="./assets/logo.svg">
      <h1 class="text-blue-dark">Coming Soon</h1>
    </div>
  </div>
</template>

<script>
import SiteHeader from './views/atoms/SiteHeader.vue';
export default {
  name: 'App',
  components: {
    SiteHeader
  },
  computed: {
    showTest() {
      return window.location.hostname.includes('firebaseapp') || window.location.hostname.includes('localhost');
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat&display=swap');
h1, h2 {
  @apply font-header !important;
}

h1 {
  font-size: 4rem !important;
}
h2 {
  font-size: 3rem !important;
}
h3 {
  font-size: 2.25rem !important;
}
h4 {
  font-size: 1.5rem !important;
}
html {
  @apply font-body text-sm !important;
}
@media (max-width:750px) {
  h1 {
    font-size: 3rem !important;
  }
  h2 {
    font-size: 1.875rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  h4 {
    font-size: 1.125rem !important;
  }
}

</style>
