<script>
  export default {
    name: 'SiteButton'
  }
</script>

<template>
  <button class="bg-green-dark text-white font-bold py-3 px-4 rounded-full" @click="$emit('button-click')">
    <h4>
      <slot />
    </h4>
  </button>
</template>

<style scoped>

</style>