<script>
  export default {
    name: 'NotFound'
  }
</script>

<template>
  <div>
    <h2>Nope</h2>
  </div>
</template>

<style scoped>

</style>