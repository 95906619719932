<template>
  <slider animation="fade" stopOnHover :height="getHeight" class="mb-5">
    <slider-item
      v-for="(i, index) in list"
      :key="index"
    >
      <div class="mx-20 md:flex items-center justify-center h-full pb-4">
        <img :src="getImgUrl(i.image)" class="mx-auto md:mx-0 md:w-1/3 md:mr-6" />
        <div>
          <h3>{{ i.title }}</h3>
          <p>{{ i.body }}</p>
          <a href="/services" class="underline text-blue-light font-bold">Learn more about our services</a>
        </div>
      </div>
    </slider-item>
  </slider>
</template>

<script>
  import { Slider, SliderItem } from 'vue-easy-slider'
  export default {
    name:'ServicesSlider',
    components: {
      Slider,
      SliderItem
    },
    computed: {
      getHeight() {
        return window.outerWidth < 750 ? '350px' : '250px'; 
      }
    },
    data() {
      return {
        list: [
          { 
            title: 'Construction Progress',
            image: 'images/1.jpg',
            body: 'Keep track of your project through aerial images'
          },
          { 
            title: 'More Construction Progress',
            image: 'images/2.jpg',
            body: 'Keep track of your project through aerial images'
          },
        ],
      }
    },
    methods: {
      getImgUrl(pic) {
        return require('../../assets/'+pic)
      }
    },
  }
</script>

<style>
  .slider-btn {
    background: none !important;
  }
  .slider-icon {
    border-left: 2px solid rgba(0,0,0,.6) !important;
    border-bottom: 2px solid rgba(0,0,0,.6) !important;
  }
</style>