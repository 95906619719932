<script>
  //import ContactForm from '@/views/organisms/ContactForm';
  import ServicesBanner from '@/views/atoms/ServicesBanner';
  import ServicesSlider from '@/views/molecules/ServicesSlider';
  import SiteButton from '@/views/atoms/SiteButton';
  export default {
    name: 'Home',
    components: {
      //ContactForm,
      ServicesBanner,
      ServicesSlider,
      SiteButton
    },
    created() {
      this.$logEvent('home_visited');
    },
  }
</script>

<template>
  <div>
    <div class="home-banner relative">
      <img src='@/assets/images/home.jpg' class="w-full h-full object-cover"/>
      <div class="bg-black opacity-25 w-full h-full absolute top-0"></div>
      <div class="absolute top-0 flex justify-center items-center h-full mx-10 flex-col md:flex-row">
        <h1 class="mb-6 md:mb-0 text-white md:text-left">Aerial imaging with results down to the centimeter</h1>
        <site-button @button-click="$router.push('contact')" class="shadow-lg flex-none w-56 ml-8">Contact Us</site-button>
      </div>
      <h2 class="text-blue-dark my-10 mx-5 md:w-2/3 md:mx-auto">We take a lot of really great pictures with drones and also we have some program that does cool stuff too</h2>
      <services-banner />
      <site-button @button-click="$router.push('services')" class="w-3/4 md:w-1/3 mt-10 md:mt-16 mb-10">Learn More</site-button>
      <services-slider />
      <!--<contact-form />-->
    </div>
  </div>
</template>

<style scoped>
  .home-banner {
    max-height: 350px;
    height: 350px;
  }
</style>