import Vue from 'vue';
import Router from 'vue-router';
import About from '../views/pages/About';
import Contact from '../views/pages/Contact';
import Gallery from '../views/pages/Gallery';
import Home from '../views/pages/Home';
import NotFound from '../views/pages/NotFound.vue';
import Services from '../views/pages/Services.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: Gallery
    },
    {
      path: '/services',
      name: Services,
      component: Services
    },
    {
      path: '*',
      name: 'Not Found',
      component: NotFound
    }
  ]
});

export default router;