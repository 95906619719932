<script>
  export default {
    name: 'ServicesBanner'
  }
</script>

<template>
  <div class="bg-blue-dark flex flex-col md:flex-row justify-between items-center p-10">
    <div class="mb-10 md:mb-0">
      <div class="h-16 mb-3">
        <img src="@/assets/icons/drone.svg" class="w-24 mx-auto" />
      </div>
      <h4 class="text-white">High quality photos and videos</h4>
    </div>
    <div class="mb-10 md:mb-0">
      <img src="@/assets/icons/data.svg" class="h-16 mx-auto mb-3" />
      <h4 class="text-white">Detailed reports with data accurate to the centimeter</h4>
    </div>
    <div class="">
      <img src="@/assets/icons/licensed.svg" class="h-16 mx-auto mb-3" />
      <h4 class="text-white">Licensed and experienced</h4>
    </div>
  </div>
</template>

<style scoped>

</style>